import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectState as selectUserState } from './../user/user.selectors';
import {
  AuthenticationState,
  authenticationFeatureKey,
} from './authentication.reducer';

export const selectState = createFeatureSelector<AuthenticationState>(
  authenticationFeatureKey,
);

export const selectIsLoggedIntoTerminal = createSelector(
  selectState,
  (authState) => authState?.isLoggedIntoTerminal,
);

export const selectSimpleAuthKeyAndId = createSelector(
  selectState,
  (state) => state?.authKeyAndId ?? null,
);

export const selectIsSimpleAuth = createSelector(
  selectState,
  (state) => !!state?.authKeyAndId,
);

export const selectIsFullyLogged = createSelector(
  selectState,
  selectUserState,
  selectSimpleAuthKeyAndId,
  (authState, userState, isSimpleAuth) => {
    return !!(
      authState?.isLogged &&
      ((userState?.currentUser?.terminal && authState?.isLoggedIntoTerminal) ||
        (userState?.currentUser && userState?.currentUser?.terminal === null) ||
        !!isSimpleAuth)
    );
  },
);

export const selectIsLogged = createSelector(
  selectState,
  (authState) => authState?.isLogged,
);

export const selectLoginButtonState = createSelector(
  selectState,
  (authState) => authState?.login_spinner,
);

export const selectResetButtonState = createSelector(
  selectState,
  (authState) => authState?.reset_spinner,
);

export const selectRegistertButtonState = createSelector(
  selectState,
  (authState) => authState?.register_spinner,
);

export const selectConfirmEmailLoadingState = createSelector(
  selectState,
  (state) => state?.confirmEmailLoading,
);

export const selectConfirmEmailResult = createSelector(
  selectState,
  (state) => state?.confirmEmailSuccessOrFail,
);

export const selectShowSimpleLoginForm = createSelector(
  selectUserState,
  (userState) => {
    return !userState?.currentUser?.terminal;
  },
);

export const selectTerminalType = createSelector(
  selectUserState,
  (userState) => {
    return userState?.currentUser?.terminal?.type;
  },
);

export const selectTerminalPrivacy = createSelector(
  selectUserState,
  (userState) => {
    return userState?.currentUser?.terminal?.privacy;
  },
);

export const selectRoomLoginBar = createSelector(
  selectState,
  (authState) => authState.roomLoginBar,
);

export const roomDetailsLoadingBar = createSelector(
  selectState,
  (authState) => authState.roomDetailsLoadingBar,
);

export const selectInvitationError = createSelector(
  selectState,
  (authState) => authState.invitationError,
);

export const selectInvitationResult = createSelector(
  selectState,
  (authState) => authState.invitationResult,
);

export const selectUnauthSpinner = createSelector(
  selectState,
  (authState) => authState.unauthSpinner,
);

export const selectUnauthAggregatedOrderFeature = createSelector(
  selectState,
  (authState) => authState.unauthAggregatedOrderFeature,
);

export const selectUnauthInvalidUrlMessage = createSelector(
  selectState,
  (authState) => authState.unauthInvalidUrlMessage,
);

export const selectRedirectionUrl = createSelector(
  selectState,
  (authState) => authState.redirectionUrl,
);
