import {
  AfterViewInit,
  DestroyRef,
  Directive,
  Input,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgControl } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { clearFormErrors } from 'src/app/redux/global/global.actions';
import { selectFormErrors } from 'src/app/redux/global/global.selectors';

@Directive({
  selector:
    '[formControl][serverFieldError], [formControlName][serverFieldError]',
  standalone: true,
})
export class ServerFieldErrorDirective implements AfterViewInit {
  private controlDir = inject(NgControl, { self: true });
  private destroyRef = inject(DestroyRef);
  private store = inject(Store);

  private serverErrorShown = false;

  @Input() controlName: string;

  ngAfterViewInit(): void {
    this.controlDir?.control?.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.handleServerError();
      });
    this.store
      .pipe(
        select(selectFormErrors),
        filter((error) => !!error?.[this.controlDir.name || this.controlName]),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((error) => {
        this.serverErrorShown = true;
        this.controlDir.control.setErrors(
          { error: error[this.controlDir.name || this.controlName] },
          { emitEvent: true },
        );
      });
  }

  handleServerError = () => {
    if (this.serverErrorShown) {
      this.serverErrorShown = false;
      this.store.dispatch(
        clearFormErrors({
          payload: this.controlName || this.controlDir.name.toString(),
        }),
      );
    }
  };
}
