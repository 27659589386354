import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  private http = inject(HttpClient);

  // enables or disables autofocus for the field-with-errors
  enableAutofocus = new BehaviorSubject(true);

  // reset add access form on the /manage/consumers page
  resetAddConsumer = new Subject<void>();
  // reset invite user form on the /manage/accesses page
  resetInviteUser = new Subject<void>();
  // reset course form on the /course page
  resetCourse = new Subject<void>();

  get = <O>(
    url: string,
    params?: any,
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text',
  ) =>
    this.http.get<O>(url, {
      params,
      responseType: (responseType ?? 'json') as any,
    });

  post = <I, O>(url: string, data: I, params?: any) =>
    this.http.post<O>(url, data, { params });

  patch = <I, O = I>(url: string, data: I, params?: any) =>
    this.http.patch<O>(url, data, { params });

  delete = <O>(url: string, params?: any, body?: object) =>
    this.http.request<O>('delete', url, { params, body });
}
