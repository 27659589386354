import { createFeatureSelector, createSelector } from '@ngrx/store';

import { selectOrdersRouterExtras } from '../orders/orders.selectors';
import {
  selectIsAgent,
  selectIsManager,
  selectTerminal,
} from '../user/user.selectors';
import { GlobalState, globalFeatureKey } from './global.reducer';
import { TerminalPrivacy } from 'src/app/shared/models/user';

export const selectState = createFeatureSelector<GlobalState>(globalFeatureKey);

export const selectGlobalLanguage = createSelector(
  selectState,
  (state) => state?.language,
);

export const selectShowConsumerInfo = createSelector(
  selectTerminal,
  selectIsManager,
  selectIsAgent,
  selectOrdersRouterExtras,
  (term, manager, agent, item) =>
    !!(
      ((manager || agent) && (term || item)) ||
      (term && term.privacy !== TerminalPrivacy.ANONYMOUS)
    ),
);

export const selectShowConsumerInfoDetail = createSelector(
  selectTerminal,
  selectIsManager,
  selectIsAgent,
  selectOrdersRouterExtras,
  (term, manager, agent, item) =>
    !!(
      ((manager || agent) && item) ||
      (term && term.privacy === TerminalPrivacy.SHOW_ALL)
    ),
);

export const selectFormErrors = createSelector(
  selectState,
  (state) => state?.formErrors,
);

export const selectAutocompleteSearch = createSelector(
  selectState,
  (state) => state?.autocompleteSearch,
);

export const selectGlobalError = createSelector(
  selectState,
  (state) => state?.global_error,
);
