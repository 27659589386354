import { Directive, Input, OnInit, DestroyRef, inject } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { select, Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';

import { NonFieldError } from '../models/globals';
import { clearFormErrors } from 'src/app/redux/global/global.actions';
import { selectFormErrors } from 'src/app/redux/global/global.selectors';

export const NON_FIELD_ERRORS = 'non_field_errors';

@Directive({
  selector: 'form[formGroup][serverFormError]',
  standalone: true,
})
export class ServerFormErrorDirective implements OnInit {
  private destroyRef = inject(DestroyRef);
  private form = inject(ControlContainer);
  private store = inject(Store);

  @Input() formId: string;

  private serverErrorShown: boolean;

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.serverErrorShown) {
          this.serverErrorShown = false;
          this.store.dispatch(
            clearFormErrors({
              payload: NON_FIELD_ERRORS,
            }),
          );
        }
      });
    this.store
      .pipe(
        select(selectFormErrors),
        map((error) =>
          error?.[NON_FIELD_ERRORS]?.find(
            (err) => err.form_id === this.formId || err.form_id === undefined,
          ),
        ),
        filter((nonFieldError: NonFieldError) => !!nonFieldError),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((error) => {
        this.serverErrorShown = true;
        this.form.control.setErrors(
          { error: error.message },
          { emitEvent: true },
        );
      });
  }
}
